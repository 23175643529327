import { useEffect, useState } from "react";
import "./signin.css";
import { useNavigate } from "react-router";
import AlertPopup from "../Alert/alert";
import mainPicture from "../../images/signin.jpg";
import { useLocation } from "react-router-dom";

function Signin({ setLogin, login, message }) {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const [openlogin, setOpenlogin] = useState(false);

    useEffect(() => {
        setOpenlogin(!login);
    }, [login]);

    const navigate = useNavigate();
    const location = useLocation();
    const information = location?.state?.message;

    const handlesignin = () => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (!username || username?.length === 0) {
            setError("Please enter your email.");
            return;
        } else if (!re.test(username)) {
            setError("Please enter a valid email.");
            return;
        }
        fetch(process.env.REACT_APP_BASEURL + "/members/signin", {
            method: "post",
            body: JSON.stringify({ email: username, password }),
            headers: { "content-type": "application/json" },
        })
            .then((res) => res.json())
            .then((data) => {
                if (data["error"]) {
                    setError(data["error"]);
                } else {
                    localStorage.setItem("user", JSON.stringify(data));
                    setLogin(true);
                    navigate("/dashboard");
                }
            });
    };
    const handleErrorpopup = () => {
        setError(null);
    };

    const keyDownHandler = (event) => {
        if (event.key === "Enter") {
            handlesignin();
        }
    };

    useEffect(() => {
        document.addEventListener("keydown", keyDownHandler);
        return () => {
            document.removeEventListener("keydown", keyDownHandler);
        };
    }, [username, password, keyDownHandler]);

    return (
        <>
            <div className="row">
                <div className="col-md-6">
                    <div className="demo-content">
                        <img
                            src={mainPicture}
                            alt=""
                            style={{ width: "100%" }}
                        />
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="demo-content bg-alt">
                        <div
                            className="mx-auto"
                            style={{
                                maxWidth: "600px",
                                padding: "0 2rem",
                            }}>
                            <h2>Clifty Pins Members</h2>
                            Welcome back to Clifty! Sign in to access your
                            campaign dashboard to see digital collectibles
                            delivered and more.
                            <hr />
                            <br />
                            {error && (
                                <AlertPopup
                                    message={error}
                                    variant="danger"
                                    setShow={handleErrorpopup}
                                />
                            )}
                            {information && (
                                <AlertPopup
                                    message={information}
                                    variant="primary"
                                    setShow={handleErrorpopup}
                                />
                            )}
                            {openlogin && message && (
                                <AlertPopup
                                    message={message}
                                    variant="primary"
                                    setShow={() =>
                                        setOpenlogin(false)
                                    }></AlertPopup>
                            )}
                            <div
                                className="mx-auto"
                                style={{
                                    maxWidth: "600px",
                                    padding: "0 2rem",
                                }}>
                                <h3 className="login-heading mb-4">Sign in</h3>

                                <form className="form-signin">
                                    <div className="form-floating mb-3">
                                        <input
                                            name="email"
                                            type="email"
                                            className="form-control"
                                            onChange={(e) =>
                                                setUsername(e.target.value)
                                            }
                                            autoComplete="off"
                                        />
                                        <label htmlFor="email">
                                            Email address
                                        </label>
                                    </div>
                                    <div className="form-floating mb-3">
                                        <input
                                            name="password"
                                            className="form-control"
                                            type="password"
                                            onChange={(e) =>
                                                setPassword(e.target.value)
                                            }
                                            autoComplete="off"
                                        />
                                        <label htmlFor="password">
                                            Password
                                        </label>
                                    </div>
                                    <button
                                        className="btn btn-lg btn-primary btn-login text-uppercase fw-bold mb-4 w-100"
                                        style={{ fontSize: "16px" }}
                                        onClick={handlesignin}
                                        type="button">
                                        Sign in
                                    </button>
                                </form>

                                <br />
                                <div
                                    className="col-md-2 col-lg-4 mx-auto"
                                    style={{
                                        width: "100%",
                                        textAlign: "center",
                                    }}>
                                    <p className="styles">
                                        <a href="/forgotpassword">
                                            Forgot Password?
                                        </a>
                                    </p>
                                    <p>
                                        Not a collector? Please{" "}
                                        <a href="/signup">Sign Up</a> here for a
                                        FREE account.
                                        <br />
                                        <br />
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default Signin;
