import { useNavigate } from "react-router";
import cliftylogo from "../../images/clifty-transparents.png";
import AlertPopup from "../Alert/alert";
import { Link, NavLink } from "react-router-dom";

import "./header.css";
import { useContext, useEffect } from "react";
import { AlertContext } from "../../App";

function Header({ login, setLogin, setmsg }) {
    const navigate = useNavigate();
    const alertContext = useContext(AlertContext);

    const setShow = () => {
        alertContext.show = false;
    };
    const handlesignout = () => {
        setLogin(false);
        setmsg("You’ve been successfully signed out. See you next time!");
        localStorage.removeItem("user");
        navigate("/");
    };

    const handleDrawer = () => {
        const el = document.getElementById("navbarNav");
        el.style.display = el.style.display === "none" ? "block" : "none";
    };

    const user = localStorage.getItem("user");

    useEffect(() => {
        if (user) {
            setLogin(true);
        } else {
            setLogin(false);
        }
    }, [setLogin, user]);

    return (
        <>
            <nav
                className="navbar navbar-expand-lg navbar-light"
                style={{ padding: 0, backgroundColor: "#F4F9FF" }}>
                <div className="container-fluid">
                    <Link to="/dashboard" className="navbar-brand">
                        <img
                            src={cliftylogo}
                            className="img"
                            alt=""
                            style={{ marginRight: "6px" }}
                        />
                        Clifty Pins Member
                    </Link>
                    <button
                        className="navbar-toggler"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarNav"
                        aria-controls="navbarNav"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                        onClick={handleDrawer}>
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div
                        className="collapse navbar-collapse justify-content-end"
                        style={{ display: "none" }}
                        id="navbarNav">
                        {login && (
                            <ul className="navbar-nav">
                                <li className="nav-item">
                                    <NavLink
                                        className={({ isActive }) =>
                                            isActive
                                                ? "nav-link active-navlink"
                                                : "nav-link"
                                        }
                                        to="/dashboard"
                                        end>
                                        Dashboard
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink
                                        className={({ isActive }) =>
                                            isActive
                                                ? "nav-link active-navlink"
                                                : "nav-link"
                                        }
                                        to="/dashboard/pins"
                                        end>
                                        Pins
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink
                                        className={({ isActive }) =>
                                            isActive
                                                ? "nav-link active-navlink"
                                                : "nav-link"
                                        }
                                        to="/dashboard/profile"
                                        end>
                                        Profile
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink
                                        className="nav-link"
                                        onClick={handlesignout}
                                        to="/"
                                        end>
                                        Signout
                                    </NavLink>
                                </li>
                            </ul>
                        )}
                    </div>
                </div>
            </nav>

            <div className="container">
                {alertContext.show && (
                    <AlertPopup
                        message={alertContext.msg}
                        variant={alertContext.variant}
                        setShow={() => setShow()}
                    />
                )}
            </div>
        </>
    );
}

export default Header;
