import { Outlet, useLocation, useNavigate } from "react-router";
import "./pins.css";
import { Form } from "react-bootstrap";
import { useEffect, useState } from "react";

const AllPins = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [filter, setfilter] = useState();
  const handlefilter = ({ currentTarget: input }) => {
    const { value } = input;
    if (value === "") {
      navigate("..", { relative: "path" });
    } else {
      navigate("active", { relative: "path" });
    }
  };

  useEffect(() => {
    setfilter(location.pathname.replace("/dashboard/pins/", ""));
    console.log(location.pathname);
  }, [location]);
  useEffect(() => {
    console.log(filter);
  }, [filter]);
  return (
    <div className="container">
      <br />
      <h2>All Pins</h2>
      <div className="pins-header">
        <a className="detail-link" href="/newpin">
          Add New
        </a>
        <Form.Select
          className="select-filter"
          aria-label="Default select example"
          onChange={handlefilter}
          value={filter}
        >
          <option value="">All</option>
          <option value="active">Active</option>
        </Form.Select>
      </div>
      <br />
      <br />

      <Outlet />
    </div>
  );
};

export default AllPins;
