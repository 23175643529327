import { useContext, useEffect, useState } from "react";
import "./pindetails.css";
import { useNavigate } from "react-router";
import { AlertContext } from "../../App";
import QRCode from "react-qr-code";
import { Link } from "react-router-dom";

const EventDetail = () => {
    const [event, setEvent] = useState();
    const [stats, setStats] = useState();
    const alertContext = useContext(AlertContext);
    const navigate = useNavigate();

    const localPinID = window.location.pathname.split("/")[2];

    useEffect(() => {
        const getEvent = async () => {
            const user = JSON.parse(localStorage.getItem("user"));
            if (!user) {
                return navigate("/");
            }
            try {
                const res = await fetch(
                    `${process.env.REACT_APP_BASEURL}/members/pins/${localPinID}`,
                    {
                        method: "GET",
                        headers: {
                            Authorization: `Bearer ${user.sessionToken}`,
                        },
                    }
                );

                const data = await res.json();
                setEvent(data);
            } catch (err) {
                alertContext.show = true;
                alertContext.variant = "danger";
                if (err.toString()?.includes("Forbidden")) {
                    alertContext.msg = "Session expired, please sign in";
                    localStorage.removeItem("user");
                    navigate("/");
                } else {
                    alertContext.msg = "Error getting pins";
                }
            }
        };

        const getStats = async () => {
            const user = JSON.parse(localStorage.getItem("user"));
            if (!user) {
                return navigate("/");
            }

            try {
                const res = await fetch(
                    `${process.env.REACT_APP_BASEURL}/members/stats/${localPinID}`,
                    {
                        method: "GET",
                        headers: {
                            Authorization: `Bearer ${user.sessionToken}`,
                        },
                    }
                );
                const data = await res.json();
                setStats(data);
            } catch (err) {
                alertContext.show = true;
                alertContext.variant = "danger";
                if (err.toString()?.includes("Forbidden")) {
                    alertContext.msg = "Session expired, please sign in";
                    localStorage.removeItem("user");
                    navigate("/");
                } else {
                    alertContext.msg = "Error getting pins";
                }
            }
        };

        getEvent();
        getStats();
    }, [alertContext, localPinID, navigate]);

    return (
        <div className="eventDetail">
            <div>
                {event && stats && (
                    <>
                        <Link
                            to="/dashboard/pins"
                            style={{ textDecoration: "none" }}>
                            &larr; Back to Pins
                        </Link>
                        <div align="center">
                            <h1>{event.name}</h1>
                            <h4 style={{ color: "silver", fontSize: "14px" }}>
                                {event.description}
                            </h4>
                            <br />
                            <div>
                                <img
                                    src={`${event?.artifact?.replace(
                                        "ipfs://",
                                        "https://ipfs.io/ipfs/"
                                    )}`}
                                    alt=""
                                    width={"40%"}
                                    style={{ border: "1px solid silver" }}
                                />
                                <br />
                            </div>
                            <br />
                            <div className="card" style={{ width: "36rem" }}>
                                <div className="card-body">
                                    <p
                                        className="card-text"
                                        style={{ fontSize: "16px" }}>
                                        {event.mintnetwork}
                                    </p>

                                    {event.limitperperson > 0 && (
                                        <p>
                                            <b>Limit per person:</b>{" "}
                                            {event.limitperperson} <br />
                                        </p>
                                    )}
                                    <p>
                                        <b>Status:</b>{" "}
                                        {event.active ? "Active" : "Inactive"}
                                    </p>

                                    <p>
                                        <b>Type:</b>{" "}
                                        {event.evtype === "QR"
                                            ? "Unique QR for everyone"
                                            : event.evtype}
                                    </p>

                                    <p>
                                        {event.evtype ===
                                            "DIGITAL COLLECTIBLE" && (
                                                <>
                                                    <b>QR Id: </b>
                                                    {event.qrid} <br />
                                                </>
                                            )}
                                    </p>
                                </div>
                            </div>
                            {event.active === 1 && (
                                <>
                                    <br />
                                    <div
                                        className="card"
                                        style={{ width: "36rem" }}>
                                        <div className="card-body">
                                            <p
                                                className="card-text"
                                                style={{ fontSize: "16px" }}>
                                                Distribution Link
                                            </p>

                                            <p>
                                                {process.env
                                                    .REACT_APP_PINSHAREURL +
                                                    "/qrs/" +
                                                    event.qrid}
                                                <br /> <br />
                                                <QRCode
                                                    value={
                                                        process.env
                                                            .REACT_APP_PINSHAREURL +
                                                        "/qrs/" +
                                                        event.qrid
                                                    }
                                                />
                                            </p>
                                        </div>
                                    </div>
                                    <br />
                                    <div
                                        className="card"
                                        style={{ width: "36rem" }}>
                                        <div className="card-body">
                                            <p
                                                className="card-text"
                                                style={{ fontSize: "16px" }}>
                                                Verification Link
                                            </p>

                                            <p>
                                                {process.env
                                                    .REACT_APP_PINSHAREURL +
                                                    "/verify/" +
                                                    event.qrid}
                                                <br /> <br />
                                                <QRCode
                                                    value={
                                                        process.env
                                                            .REACT_APP_PINSHAREURL +
                                                        "/verify/" +
                                                        event.qrid
                                                    }
                                                />
                                            </p>
                                        </div>
                                    </div>
                                </>
                            )}
                            <div
                                className="card"
                                style={{ width: "18rem", margin: "1.5rem 0" }}>
                                <div className="card-body">
                                    <h5 className="card-title">Total Tokens</h5>
                                    <p
                                        className="card-text"
                                        style={{ color: "blue" }}
                                        onClick={() =>
                                            navigate(`/tokens/${localPinID}`)
                                        }>
                                        {event.maxtokens}
                                    </p>
                                </div>
                            </div>
                            <div
                                className="card"
                                style={{
                                    width: "18rem",
                                    marginBottom: "1.5rem",
                                }}>
                                <div className="card-body">
                                    <h5 className="card-title">Issued</h5>
                                    <p className="card-text">{stats.total}</p>
                                </div>
                            </div>
                            <div className="card" style={{ width: "18rem" }}>
                                <div className="card-body">
                                    <h5 className="card-title">Not Claimed</h5>
                                    <p className="card-text">
                                        {stats.ungrabbed}
                                    </p>
                                </div>
                            </div>
                            {event.active === 1 && (
                                <button
                                    className="btn btn-lg btn-primary btn-login text-uppercase fw-bold mb-4"
                                    style={{
                                        fontSize: "16px",
                                        width: "18rem",
                                        marginTop: "2rem",
                                    }}
                                    onClick={() =>
                                        navigate(`/pins/email/${localPinID}`)
                                    }
                                    type="button">
                                    Send Pins
                                </button>
                            )}
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default EventDetail;
