import "./footer.css";

function Footer() {
    return (
        <footer className="text-lg-start" style={{ background: "#F4F9FF" }}>
            <hr className="m-0" />
            <div className="text-center" style={{ width: "100%" }}>
                <span style={{ fontWeight: 500 }}>@ 2024 </span>
                <a href="https://clifty.io" rel="noreferrer" target="_blank">
                    Clifty Inc.
                </a>
                <br />
                <a href="/terms">Terms Of Service</a> |{" "}
                <a href="/privacy">Privacy Policy</a>
            </div>
        </footer>
    );
}

export default Footer;
