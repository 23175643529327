import "./terms.css";

const Terms = () => {
    return (
        <>
            <div>
                <h1>Terms of Service</h1>
                Last Updated: Jul 21, 2023
                <br />
                <br />
                <p>
                    Below are the Terms and Conditions (these “Terms”) governing
                    Your use of Clifty, Inc.'s (d/b/a Clifty) (“Clifty”, “we”,
                    “us” or our”) websites, applications, and related products
                    and services (the “Services”) or any other websites provided
                    by or on behalf of us and that are made subject to these
                    Terms, and any content, tools, forums, chat servers,
                    features and functionality offered on or through such
                    websites, or otherwise operated by us.
                    <br />
                    <br />
                    These Terms contain very important information regarding
                    Your rights and obligations, as well as conditions,
                    limitations, and exclusions that might apply to You. By
                    accessing and/or using the Services, You are agreeing to
                    these Terms. Please read it carefully.
                    <br />
                    <br />
                    For purposes of these Terms, “User”, “You” and “Your” means
                    you as the user of the Services. As used herein, “you” means
                    a participant in the Member Program. If you are entering
                    into these Terms on behalf of a company or other legal
                    entity, you represent that you have the authority to bind
                    such entity to these Terms, in which case the term “you”
                    will refer to such entity.
                    <br />
                    <br />
                    THESE TERMS CONTAIN IMPORTANT PROVISIONS INCLUDING AN
                    ARBITRATION PROVISION THAT REQUIRES YOU AND CLIFTY TO
                    RESOLVE ALL DISPUTES BY BINDING ARBITRATION INSTEAD OF IN
                    COURT. PLEASE SEE PART VI TITLED “DISPUTE RESOLUTION BY
                    BINDING ARBITRATION AND CLASS ACTION WAIVER” BELOW. YOU
                    WAIVE YOUR RIGHT TO TRIAL BY JURY, AND EXCEPT AS PERMITTED
                    BY LAW, YOU WILL NOT BE ABLE TO BRING ANY CLAIMS IN STATE OR
                    FEDERAL COURT. YOU HAVE THE RIGHT TO OPT OUT OF ARBITRATION
                    AS EXPLAINED IN PART VI BELOW.
                </p>
                <h2>Part I - LEGAL REQUIREMENTS OF USE OF THE SERVICES</h2>
                <p>
                    Prior to Your accessing and/or using of the Services, You
                    shall closely review these Terms and our privacy policy
                    ("Privacy Policy”) (see Part IV Section 3 below). If You do
                    not agree with these Terms and Privacy Policy, do not access
                    the Services for any purpose. Your access to, and use of,
                    the Services is evidence of Your acceptance of these Terms
                    and Privacy Policy and all applicable laws, statutes, and
                    ordinances. You must use the Services in accordance with all
                    applicable international, federal, state, and local laws and
                    may not post or transmit anything that would give rise to
                    any civil or criminal liability for any party or otherwise
                    violate any law.
                </p>
                <h2>Part II - THE SERVICES</h2>
                <h3>1. DEFINED TERMS</h3>
                <p>
                    In addition to the capitalized terms used and defined
                    throughout these Terms, the following capitalized terms have
                    the following meanings:
                    <br />
                    <br />
                    a. “Collector User” means Users that buy or otherwise
                    acquire NFTs for the Collector via the Services. <br />
                    <br />
                    b. “Content” means content, materials, art, design, and
                    drawings (in any form or media, including, without
                    limitation, video, audio, or photographs). <br />
                    <br />
                    c. “NFT” means a non-fungible token or similar digital item
                    implemented on a blockchain that uses smart contracts to
                    link or otherwise be associated with certain Content or
                    other data. The NFT is separate and distinct from the
                    Content or any other Third Party IP (as defined below) with
                    which it may be linked or associated. <br />
                    <br />
                    d. “Services Wallet” means a digital wallet that is
                    compatible with the applicable blockchain on which the
                    Clifty NFTs may be stored or non-transferred. <br />
                    <br />
                    e. “Payments Account” means a third-party payment provider
                    account (such as a Circle payments account), which may or
                    may not sit on a blockchain, and that is used to facilitate
                    payments on the Services. <br />
                    <br />
                    f. “Clifty Member” means Users that request for minting and
                    transfer NFTs via the Services <br />
                    <br />
                    g. “Third Party IP” means any third-party copyrights, trade
                    secrets, trademarks, know-how, patent rights, or any other
                    intellectual property rights recognized in any country or
                    jurisdiction in the world. <br />
                    <br />
                    h. “Clifty NFT/ PiNs” means an NFT that is minted and sold
                    directly by or on behalf of Clifty through the Services, and
                    that is associated with specific Content made available by
                    Clifty or its licensors.
                </p>
                <h3>2. THE SERVICES</h3>
                <p>
                    Certain Clifty NFTs may have utility in collection
                    challenges and game experiences which may be offered via the
                    Services from time to time. To ensure that the uniqueness
                    and ownership of each Clifty NFT offered via the Services is
                    accurately tracked, we use blockchain-based NFT technology
                    to demonstrate and record ownership and history of the
                    Clifty NFT. We mint each Clifty NFT on a publicly available
                    blockchain (such as the Flow blockchain). Once committed to
                    the blockchain, the NFT cannot be edited or changed,
                    including by us or any other third party. NFTs are not used
                    to make payments and are not mutually interchangeable. These
                    design features limit the usefulness of NFTs as a form of
                    payment or substitute for currency. Instead, NFTs, including
                    any Clifty NFTs that are minted as NFTs, are only intended
                    to be used for enjoyment as digital collectibles.
                </p>
                <h3>3. NFT TRANSACTIONS</h3>
                <p>
                    The Services may facilitate transactions for purchases of
                    NFTs for Collector Users by Member users. However, we may
                    not be a party to any agreements between Collector users and
                    Member Users on the Services, and we’re not responsible for
                    any breach or default by a Collector User or a Member user.
                </p>
                <h3>4. USER ACCOUNTS AND WALLETS</h3>
                <p>
                    a. Eligibility. You must be at least 18 years of age to use
                    the Services. By using the Services, You represent and
                    warrant that You are at least 18 years of age. Only
                    individual, natural persons may use the Services. You
                    further represent and warrant that You reside in a
                    jurisdiction where the Services are offered and You are
                    familiar with all relevant local laws of the jurisdiction in
                    which You reside. <br />
                    <br />
                    b. One Account Per User. Each user may only have one
                    Account. We reserve the right, in our sole discretion, to
                    determine whether You have or control more than one Account.
                    Clifty further reserves the right to suspend or terminate
                    any Account that it deems to be in excess of the one Account
                    permitted per individual user. During any period of
                    suspension of an Account, You will not be able to perform
                    any actions on the Services through that Account. <br />
                    <br />
                    c. Payments Accounts. To transact in Clifty NFTs via the
                    Services (sending NFTs), You will need to create an Account
                    and create and link (i) a Payments Account with that
                    Account. Each Payments Account can only be linked to one
                    Account. All NFTs that You send to the Collectors Account
                    will be stored in the Collectors Account's designated
                    Services Wallet, and Your funds which can be used to engage
                    in transactions via the Services shall be through Your
                    Payments Account which is linked to Your Account. At this
                    time, Your use of the Payments Account is subject to the
                    terms and conditions of the actual service provider operator
                    of the Payments Account. Users must familiarize themselves
                    with the terms of use, technology, and security protocols of
                    any such third-party wallet. For instance, the terms and
                    conditions applicable to the use of a Payments Account
                    operated by Stripe Payments Company, Inc. are set forth at
                    &nbsp;
                    <a
                        href="https://stripe.com/en-gb-us/legal/payment-terms"
                        target="_blank"
                        rel="noreferrer">
                        https://stripe.com/en-gb-us/legal/payment-terms
                    </a>
                    . Do not deposit, transfer, upload (or attempt any of the
                    foregoing) any other cryptocurrency, NFTs, or other digital
                    assets from outside the applicable Services into any
                    Services Wallet or Payments Account, unless Clifty or the
                    applicable Payments Account service provider expressly
                    permits You to do so as part of the functionality of the
                    Services Wallet or Payments Account, as applicable. Any such
                    transferred item or items may be lost, and Clifty assumes no
                    responsibility for any such loss. We may terminate the
                    offering or support of the Payments Account at any time, in
                    our sole discretion. <br />
                    <br />
                    d. Verification and Payment.
                    <br />
                    <br />
                    i. In order to transact on the Services, we or our service
                    providers may require You to provide additional information
                    and documentation as required by applicable law or
                    regulation, including laws related to anti-money laundering.
                    In such cases, we, in our sole discretion, may pause or
                    cancel Your transactions within the applicable Services
                    until we, and/or our service providers, have reviewed these
                    documents and accepted them as satisfying the requirements
                    of applicable law. If You do not provide complete and
                    accurate information and documentation in response to such a
                    request, Your transactions may not be processed on the
                    Services. <br />
                    <br />
                    ii. You acknowledge that there may also be a time delay
                    between the time when You submit such information and when
                    we and/or our service providers can complete any anti-money
                    laundering, know-your-client or similar checks. You
                    acknowledge that with respect to Clifty NFTs, Clifty may
                    incorporate functionality into any Clifty NFT or the
                    applicable Service that imposes a fee on any secondary sales
                    or transfers for the value of such Clifty NFTs by a User
                    (e.g., to another User who collects Clifty NFTs) based on
                    the value of any such transfer and Clifty may charge a
                    listing fee for any transactions taking place on or in
                    connection with the Services. <br />
                    <br />
                    iii. We have no liability to You or to any third party for
                    any claims or damages that may arise as a result of any
                    payments or transactions that You engage in via the Services
                    or any other payment or transactions that You conduct via
                    the Services. All sales are final. We do not provide returns
                    or refunds for any purchases that You might make on or
                    through the Services - whether for Clifty NFTs (whether sold
                    as individual NFTs or in packs) or anything else.
                    <br />
                    <br />
                    iv. You are solely responsible to pay any and all sales,
                    use, value-added, and other taxes, duties, and assessments
                    (except taxes on our net income) now or hereafter claimed or
                    imposed by any governmental authority associated with Your
                    use of the Services (including, without limitation, any
                    taxes that may become payable as the result of Your
                    ownership, transfer, purchase, or sale of any NFT). Except
                    for income taxes levied on us, You: (i) will be solely
                    responsible for reporting any tax obligations when, if ever,
                    such obligations arise as a result of Your use of the
                    Services or about a Clifty NFT; (ii) will pay or reimburse
                    Clifty and/or our service provider(s) (as applicable) for
                    all national, federal, state, local or other taxes and
                    assessments of any jurisdiction, including value-added taxes
                    and other taxes as may be required, and amounts levied in
                    lieu thereof based on charges set, services performed or
                    payments made hereunder, and any withholding taxes as are
                    now or hereafter may be imposed under the authority of any
                    national, state, local or any other taxing jurisdiction; and
                    (iii) will not be entitled to deduct the amount of any such
                    taxes, duties or assessments from payments (including
                    blockchain gas fees or similar transaction fees) made to us
                    pursuant to these Terms. You agree to indemnify and hold
                    harmless Clifty for any claims, demands, liens, or judgments
                    based on non-payment or underpayment of such taxes,
                    including in our capacity as a withholding agent, or as a
                    collection agent. Upon opening Your Account, You will
                    provide to Clifty any form or document accurately completed
                    that may be required by law or requested by Clifty in order
                    to allow Clifty to make a payment to You without or at a
                    reduced rate of a deduction or withholding for any tax,
                    including without limitation, a duly completed and executed
                    U.S. Internal Revenue Service Form W-9 or an applicable Form
                    W-8 (or any successor thereto). You agree that You will
                    provide an updated form or document referred in the
                    preceding paragraph promptly upon demand by Clifty and upon
                    learning that any such form previously provided has become
                    obsolete, incorrect, or ineffective. <br />
                    <br />
                    e. Members Services Changes. The regulatory regime governing
                    blockchain technologies, cryptocurrencies, tokens, and other
                    crypto-based items is uncertain, and new regulations or
                    policies may materially adversely affect our performance of
                    the Member Services, and therefore we may from time to time,
                    at our sole discretion, revise the manner in which we
                    perform the Member Services and/or amend the type of Member
                    Services we may provide.
                </p>
                <h2>PART III - OWNERSHIP AND CONTENT</h2>
                <h3>1. THIRD-PARTY SERVICES AND MATERIALS</h3>
                <p>
                    Third-Party Materials. Various types of content, data,
                    applications, information or materials made available,
                    displayed on, or included in certain Services may have been
                    provided by third parties (“Third Party Materials”) or
                    provide links to third-party websites. This information may
                    include the views, opinions, and recommendations of such
                    third parties. By using the Services, You acknowledge and
                    agree that Clifty neither endorses such views or opinions,
                    nor is responsible for examining or evaluating the content,
                    the availability, accuracy, currency, completeness,
                    timeliness, validity, copyright compliance, legality,
                    decency, quality, reliability or any other aspect of such
                    Third Party Materials or websites, and the inclusion of such
                    views or opinions are not intended to provide advice or
                    constitute a solicitation. At no time shall anything
                    contained on the Services be deemed an endorsement or
                    recommendation of any third party or shall constitute any
                    representation as to a third party's qualifications,
                    services, products, offerings, information or any other
                    content or Third Party Materials or websites. You
                    acknowledge that under no circumstances will Clifty have or
                    assume any liability or responsibility to You or any other
                    person for any third-party services or Third Party
                    Materials, websites, or for any other materials, products,
                    or services of third parties. Third-Party Materials and
                    links to other websites are provided solely as a convenience
                    to You.
                </p>
                <h3>2. COPYRIGHT & TRADEMARKS</h3>
                <p>
                    Unless otherwise indicated, the documents and information,
                    including, but not limited to, text, graphics, icons, logos,
                    buttons, and images, on the Services are copyrighted
                    materials of, trademarks of, and owned and controlled by
                    Clifty or its affiliates, or licensors. Other names, logos,
                    product and service names, designs, and slogans that appear
                    on the Services are the property of their respective owners,
                    who may or may not be affiliated with, connected to, or
                    sponsored by us.
                </p>
                <h3>3. FEEDBACK</h3>
                <p>
                    We welcome feedback, comments, and suggestions for
                    improvements to the Services (“Feedback”). You acknowledge
                    and expressly agree that any contribution of Feedback does
                    not and will not give or grant You any right, title, or
                    interest in the Services or in any such Feedback. All
                    Feedback becomes the sole and exclusive property of Clifty,
                    and Clifty may use and disclose Feedback in any manner and
                    for any purpose whatsoever without further notice or
                    compensation to You and without retention by You of any
                    proprietary or other right or claim. You hereby assign to
                    Clifty any and all rights, titles, and interests (including,
                    but not limited to, any patent, copyright, trade secret,
                    trademark, show-how, know-how, moral rights, and any and all
                    other intellectual property right) that You may have in and
                    to any and all Feedback.
                </p>
                <h3>Part IV - USE OF THE SERVICES</h3>
                <h4>1. USE OF THE SERVICES</h4>
                <p>
                    You are responsible for Your interactions with other Users
                    of the Services. While we reserve the right to monitor
                    interactions between Users, we are not obligated to do so,
                    and we cannot be held liable for Your interactions with
                    other Users, or for any User’s actions or inactions. If You
                    have a dispute with one or more Users, You release us (and
                    our affiliates and subsidiaries, and our and their
                    respective officers, directors, employees, and agents) from
                    claims, demands, and damages (actual and consequential) of
                    every kind and nature, known and unknown, arising out of or
                    in any way connected with such disputes. In entering into
                    this release You expressly waive any protections (whether
                    statutory or otherwise) that would otherwise limit the
                    coverage of this release to include only those claims which
                    You may know or suspect to exist in Your favor at the time
                    of agreeing to this release.
                </p>
                <h4>2. PROHIBITED ACTIVITIES</h4>
                <p>
                    a. Illegal Activities. You are responsible for complying
                    with applicable laws, including state and federal laws and
                    regulations regarding money laundering, terrorist financing,
                    and money transfer and remittance. You are responsible for
                    understanding and abiding by the laws and regulations of
                    each jurisdiction in which You use the Services. Users of
                    the account shall not engage in any illegal activities
                    according to applicable law or in relation to any other
                    Prohibited Activity listed below.
                    <br />
                    <br />
                    b. List of Prohibited Activities. We have to set some ground
                    rules on Your use of the Services so that other Users can
                    enjoy the Services, and protect our rights. Accordingly, You
                    may not do any of the following in connection with Your use
                    of the Services, unless applicable laws or regulations
                    prohibit these restrictions or You have our prior written
                    permission to do so:
                    <ul>
                        <li>
                            download, modify, copy, distribute, transmit,
                            display, perform, reproduce, duplicate, publish,
                            license, create derivative works from, or offer for
                            sale any information contained on, or obtained from
                            or through, the Services, except for temporary files
                            that are automatically cached by Your web browser
                            for display purposes, or as otherwise expressly
                            permitted in these Terms;
                        </li>
                        <li>
                            accept, receive, or purchase any Clifty NFT from
                            another User that you have any grounds to believe
                            was not rightfully in possession thereof at the time
                            of such transaction;{" "}
                        </li>
                        <li>
                            duplicate, decompile, decipher, reverse engineer,
                            disassemble, or decode the Services (including any
                            underlying idea or algorithm), or attempt to do any
                            of the same;{" "}
                        </li>
                        <li>
                            use, reproduce, or remove any copyright, trademark,
                            service mark, trade name, slogan, logo, image, or
                            other proprietary notation displayed on or through
                            the Services;{" "}
                        </li>
                        <li>
                            use cheats, automation software (bots), hacks,
                            modifications (mods), or any other unauthorized
                            third-party software designed to establish Accounts,
                            perform any transaction on the Services, or modify
                            or make use of the Services in any way;{" "}
                        </li>
                        <li>
                            exploit the Services for any commercial purpose,
                            including without limitation communicating or
                            facilitating any commercial advertisement or
                            solicitation;{" "}
                        </li>
                        <li>
                            access or use the Services in any manner that could
                            disable, overburden, damage, disrupt or impair the
                            Services or interfere with any other party’s access
                            to or use of the Services or use any device,
                            software, or routine that causes the same;{" "}
                        </li>
                        <li>
                            attempt to gain unauthorized access to, interfere
                            with, damage, or disrupt the Services, accounts
                            registered to other users, or the computer systems
                            or networks connected to the Services;{" "}
                        </li>
                        <li>
                            circumvent, remove, alter, deactivate, degrade, or
                            thwart any technological measure or content
                            protections of the Services;{" "}
                        </li>
                        <li>
                            use any robot, spider, crawler, scraper, or other
                            automatic device, process, software, or query that
                            intercepts, “mines,” scrapes, extracts, or otherwise
                            accesses the Services to monitor, extract, copy, or
                            collect information or data from or through the
                            Services, or engage in any manual process to do the
                            same;{" "}
                        </li>
                        <li>
                            introduce any viruses, trojan horses, worms, logic
                            bombs, or other materials that are malicious or
                            technologically harmful into our systems;{" "}
                        </li>
                        <li>
                            use the Services for illegal, harassing, bullying,
                            unethical or disruptive purposes, or otherwise use
                            the Services in a manner that is fraudulent,
                            defamatory, obscene, hateful, or otherwise
                            objectionable;{" "}
                        </li>
                        <li>
                            access or use the Services in any way not expressly
                            permitted by these Terms; or
                        </li>
                        <li>
                            use the Service to transact in any of the following:
                            <ul>
                                <li>
                                    Weapons of any kind, including but not
                                    limited to firearms, ammunition, knives,
                                    explosives, or related accessories;
                                </li>
                                <li>
                                    Controlled substances, including but not
                                    limited to narcotics, prescription drugs,
                                    steroids, or related paraphernalia or
                                    accessories;
                                </li>
                                <li>Prostitution;</li>
                                <li>
                                    Gambling activities include but are not
                                    limited to sports betting, casino games,
                                    horse racing, dog racing, lotteries, games
                                    of chance, sweepstakes, games of skill that
                                    may be classified as gambling (i.e., poker),
                                    or other activities that facilitate any of
                                    the foregoing;
                                </li>
                                <li>
                                    Money laundering or terrorist financing;
                                </li>
                                <li>
                                    Any sort of Ponzi scheme, pyramid scheme, or
                                    multi-level marketing program;
                                </li>
                                <li>
                                    Goods or services that infringe or violate
                                    any copyright, trademark, or proprietary
                                    rights under the laws of any jurisdiction;
                                </li>
                                <li>
                                    Debt settlement, refinance, or credit repair
                                    services;
                                </li>
                                <li>
                                    Court-ordered payments, structured
                                    settlements, tax payments, or tax
                                    settlements;
                                </li>
                                <li>
                                    Lottery contracts, layaway systems, or
                                    annuities;
                                </li>
                                <li>
                                    Counterfeit goods, including but not limited
                                    to fake or “novelty” IDs;
                                </li>
                                <li>
                                    Wash trading, front-running, insider
                                    trading, market manipulation, or other forms
                                    of market-based fraud or deceit;
                                </li>
                                <li>
                                    Purchasing goods of any type from Tor hidden
                                    service markets or “Darknet” markets, or any
                                    other service or website that acts as a
                                    marketplace for illegal goods (even though
                                    such marketplace might also sell legal
                                    goods);
                                </li>
                                <li>
                                    Transactions involving sanctioned parties,
                                    countries, or coins;
                                </li>
                                <li>ATM Operators;</li>
                                <li>Bankruptcy Services;</li>
                                Cyberlocking Services;
                                <li>Precious Metals and Jewelry;</li>
                                <li>Ransomware, Shell Banks/FIs;</li>
                                <li>Unlicensed MBS;</li>
                                <li>Miracle Cures;</li>
                                <li>Hacking Guides; or</li>
                                <li>Psychic Services.</li>
                            </ul>
                        </li>
                    </ul>
                </p>
                <p>
                    c. You are utilizing the Services for personal, private use
                    only, and You will not make any information, materials, or
                    services available from the Services available to any person
                    who is not otherwise allowed to access them. This
                    prohibition includes the use of information, materials, or
                    services related to or made available in connection with the
                    Services for commercial purposes, as well as the making of
                    such information, materials, or services available to a
                    minor in any way, shape, or form.
                </p>
                <h3>3. DATA AND PRIVACY</h3>
                <p>
                    All collection, sharing, and use of data regarding access to
                    the Services and usage of the Services are governed by our
                    Privacy Policy.
                </p>
                <h2>PART V – INDEMNIFICATION</h2>
                <p>
                    By entering into these Terms and accessing or using the
                    Services, You agree to indemnify and hold harmless Clifty,
                    its suppliers, distributors, licensors, partners,
                    subcontractors, and its affiliates and related entities and
                    their respective directors, officers, employees, and agents
                    (the “Clifty Entities”), from and against any and all
                    claims, losses, damages, liabilities, and costs (included
                    but not limited to reasonable attorneys' fees and court
                    costs) incurred by the Clifty Entities, arising out of or
                    relating to: (a) Your misuse of the Services, (b) the
                    violation or breach of these Terms by You, (c) the violation
                    by You of any rights of any third party, including but not
                    limited to infringement by You (or anyone accessing the
                    Services using Your Account) of any intellectual property or
                    other rights of any person or entity, (d) Your negligence or
                    willful misconduct, or (e) Your violation of any applicable
                    law or regulation. The foregoing indemnification obligation
                    shall survive termination of these Terms, the Services, and
                    Your purchase or sale of any product via the Services. If
                    You are obligated to indemnify any Clifty Entity hereunder,
                    then You agree that Clifty (or, at its discretion, the
                    applicable Clifty Entity) will have the right, in its sole
                    discretion, to control any action or proceeding and to
                    determine whether Clifty wishes to settle, and if so, on
                    what terms, and You agree to fully cooperate with Clifty in
                    the defense or settlement of such claim.
                </p>
                <h2>PART VI - DISCLAIMERS</h2>
                <p>
                    1. YOUR ACCESS TO AND USE OF THE SERVICES ARE AT YOUR OWN
                    RISK. YOU EXPRESSLY ACKNOWLEDGE, UNDERSTAND AND AGREE THAT
                    TO THE FULLEST EXTENT PERMISSIBLE BY APPLICABLE LAW, THE
                    CLIFTY ENTITIES DISCLAIM ALL WARRANTIES, CONDITIONS, OR
                    AFFIRMATIONS OF FACT, EXPRESS OR IMPLIED, INCLUDING, BUT NOT
                    LIMITED TO, ANY WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
                    PARTICULAR PURPOSE, OR NONINFRINGEMENT. THE CLIFTY ENTITIES
                    DO NOT MAKE ANY WARRANTY OR REPRESENTATION THAT ANY PRODUCT
                    OR SERVICE WILL MEET YOUR NEEDS OR REQUIREMENTS. YOU
                    UNDERSTAND AND AGREE THAT THE SERVICES ARE PROVIDED TO YOU
                    ON AN 'AS IS' AND 'AS AVAILABLE' BASIS. THE CLIFTY ENTITIES
                    MAKE NO WARRANTY OR REPRESENTATION AND DISCLAIM ALL
                    RESPONSIBILITY FOR: (A) WHETHER THE SERVICES WILL BE
                    AVAILABLE ON AN UNINTERRUPTED, SECURE OR ERROR-FREE BASIS,
                    (B) THE ACCURACY, COMPLETENESS, AVAILABILITY, TIMELINESS,
                    SECURITY OR RELIABILITY OF THE SERVICES, (C) ANY HARM TO
                    YOUR COMPUTER SYSTEM, LOSS OF DATA, OR OTHER HARM THAT
                    RESULTS FROM YOUR ACCESS TO OR USE OF THE SERVICES, OR (D)
                    THE OPERATION OR COMPATIBILITY WITH ANY OTHER APPLICATION OR
                    ANY PARTICULAR SYSTEM OR DEVICE. NO ADVICE OR INFORMATION,
                    WHETHER ORAL OR WRITTEN, OBTAINED FROM THE CLIFTY ENTITIES
                    OR THROUGH THE SERVICES, WILL CREATE ANY WARRANTY OR
                    REPRESENTATION NOT EXPRESSLY MADE HEREIN. <br />
                    <br />
                </p>
                <p>
                    2. THE CLIFTY ENTITIES TAKE NO RESPONSIBILITY AND ASSUME NO
                    LIABILITY FOR ANY CONTENT THAT YOU, ANOTHER USER, OR A THIRD
                    PARTY CREATES, UPLOADS, POSTS, SENDS, RECEIVES, OR STORES ON
                    OR THROUGH OUR SERVICES. <br />
                    <br />
                </p>
                <p>
                    3. YOU UNDERSTAND AND AGREE THAT YOU MAY BE EXPOSED TO
                    CONTENT THAT MIGHT BE OFFENSIVE, ILLEGAL, MISLEADING, OR
                    OTHERWISE INAPPROPRIATE, NONE OF WHICH THE CLIFTY ENTITIES
                    WILL BE RESPONSIBLE FOR.
                </p>
                <h2>PART VII - LIMITATION OF LIABILITY</h2>
                <p>
                    LIMITATIONS OF LIABILITY. TO THE EXTENT NOT PROHIBITED BY
                    LAW, YOU AGREE THAT IN NO EVENT WILL THE CLIFTY ENTITIES BE
                    LIABLE TO YOU OR TO ANY THIRD PARTY (A) FOR ANY INDIRECT,
                    INCIDENTAL, EXEMPLARY, PUNITIVE, OR CONSEQUENTIAL DAMAGES,
                    WHETHER IN CONTRACT OR TORT, INCLUDING, BUT NOT LIMITED TO,
                    LOSS OF REVENUE OR PROFITS, PROCUREMENT OF SUBSTITUTE GOODS
                    OR SERVICES, LOSS OF USE, LOSS OF DATA, BUSINESS
                    INTERRUPTION OR ANY OTHER DAMAGES OR LOSSES ARISING OUT OF
                    OR RELATED TO YOUR USE OR INABILITY TO USE THE SERVICES,
                    HOWEVER CAUSED, WHETHER UNDER THESE TERMS OR OTHERWISE
                    ARISING IN ANY WAY IN CONNECTION WITH THE SERVICES OR THESE
                    TERMS, WHETHER FORESEEABLE OR NOT AND WHETHER AN CLIFTY
                    ENTITY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES
                    OR NOT, OR (B) FOR ANY OTHER CLAIM, DEMAND OR DAMAGES
                    WHATSOEVER RESULTING FROM OR ARISING OUT OF OR IN CONNECTION
                    WITH THESE TERMS OR THE DELIVERY, USE OR PERFORMANCE OF THE
                    SERVICES. THE CLIFTY ENTITIES’ TOTAL LIABILITY TO YOU FOR
                    ANY DAMAGES FINALLY AWARDED SHALL NOT EXCEED THE AMOUNT OF
                    ONE HUNDRED DOLLARS ($100.00), OR THE AMOUNT YOU PAID THE
                    CLIFTY ENTITIES, IF ANY, IN THE PAST SIX (6) MONTHS FOR THE
                    SERVICES (OR OFFERINGS PURCHASED ON THE SERVICES) GIVING
                    RISE TO THE CLAIM. THE FOREGOING LIMITATIONS WILL APPLY EVEN
                    IF THE ABOVE-STATED REMEDY FAILS OF ITS ESSENTIAL PURPOSE.
                    Because certain jurisdictions (such as the state of New
                    Jersey) to which these Terms may be subject do not allow the
                    exclusion or limitation of liability for consequential or
                    incidental damages, the above exclusion or limitation may be
                    held to be inapplicable to You in those jurisdictions.
                </p>
                <h2>PART VIII – ASSUMPTION OF RISKS</h2>
                <p>
                    a. You acknowledge and agree that there are risks associated
                    with purchasing and sending NFTs and using blockchain
                    technology. These include, but are not limited to, the risk
                    of losing access to NFT due to loss of private key(s),
                    custodial error or purchaser error, risk of mining or
                    blockchain attacks, risk of hacking and security weaknesses,
                    risk of unfavorable regulatory intervention in one or more
                    jurisdictions, risks related to uncertainties in token
                    taxation, risk of personal information disclosure, risk of
                    uninsured losses, unanticipated risks, and volatility risks.
                    <br />
                    <br />
                </p>
                <p>
                    b. The prices of collectible blockchain assets are extremely
                    volatile and subjective and collectible blockchain assets
                    have no inherent or intrinsic value. Each Clifty NFT has no
                    inherent or intrinsic value. You acknowledge and affirm that
                    You are collecting, trading, or purchasing Clifty NFTs for
                    the purposes of acquiring digital collectibles for Your
                    personal use and enjoyment, and not for any investment,
                    commercial, or speculative purposes. Any economic benefit
                    that may be derived from appreciation in the value of the
                    Clifty NFT is incidental to obtaining it for its collectible
                    purpose. You agree that Clifty NFTs are not to be used as a
                    substitute for currency or medium of exchange, resale, or
                    redistribution and that You are not acquiring any equity or
                    other ownership or revenue-sharing interest in Clifty, its
                    affiliates, or any brand as a result of Your acquisition of
                    Clifty NFTs. You warrant and covenant that You will not
                    portray the Clifty NFTs as an opportunity to gain an
                    economic benefit or profit, or as an investment or equity
                    interest. <br />
                    <br />
                </p>
                <p>
                    c. Clifty does not represent, guarantee, or warrant the
                    accuracy or fairness of the price of any NFT sold or offered
                    for sale on or off of the Services. You agree and
                    acknowledge that Clifty is not a fiduciary nor owes any
                    duties to any User of the Services, including the duty to
                    ensure fair pricing of NFTs. <br />
                    <br />
                </p>
                <p>
                    d. We will use commercially reasonable efforts to deploy
                    secure and functional smart contracts underlying Clifty NFT
                    minted directly by Clifty. However, we will not be liable or
                    responsible to You for any failure in the intended function
                    of such smart contracts, or any bugs, viruses, exploits,
                    logic gaps, or malicious code which may be incorporated into
                    any such smart contracts, or which could be used to commit
                    fraud or otherwise cause harm. You acknowledge that You have
                    obtained sufficient information to make an informed decision
                    to purchase/receive an NFT, including carefully reviewing
                    the code of the smart contract and the NFT and fully
                    understanding and accepting the functions of the same.{" "}
                    <br />
                    <br />
                </p>
                <p>
                    e. You acknowledge and agree that if, we believe in our sole
                    discretion, or have reasonable grounds to believe, that
                    under any prior transaction, a Clifty NFT was stolen,
                    obtained by fraudulent means, with stolen funds or credit
                    card information, or otherwise in violation of these Terms,
                    all Users hereby grant us and our service providers a right
                    to cancel any fraudulent transaction or transfer all such
                    Clifty NFTs in question from the Services Wallet or other
                    wallet, to the User who was previously in rightful
                    possession thereof, even if the User who loses possession of
                    the NFT was a good faith holder of the Clifty NFT. <br />
                    <br />
                </p>
                <p>
                    f. The regulatory regime governing blockchain technologies,
                    cryptocurrencies, tokens, and other crypto-based items is
                    uncertain, and new regulations or policies may materially
                    adversely affect the development of certain of our Services,
                    and therefore the potential utility or value of Your Clifty
                    NFTs. Upgrades to any blockchain network or hard forks in
                    such networks, or a change in how transactions are confirmed
                    on such blockchain networks may have unintended, adverse
                    effects on all blockchains, including any that are related
                    to Your Clifty NFTs. <br />
                    <br />
                </p>
                <p>
                    g. Any purchase or sale You make, accept or facilitate
                    outside of the Services of any Clifty NFT will be entirely
                    at Your risk. We do not control or endorse purchases or
                    sales of Clifty NFTs outside of the applicable Services on
                    which such Clifty NFTs were initially offered (including
                    other platforms which we may offer as part of the Services).
                    We expressly deny and disclaim any liability to You and deny
                    any obligation to indemnify You or hold You harmless for any
                    losses You may incur by transacting or facilitating
                    transactions, in NFTs outside of the Services on which the
                    Clifty NFT was initially offered.
                </p>
                <h2>
                    PART IX – DISPUTE RESOLUTION BY BINDING ARBITRATION AND
                    CLASS ACTION WAIVER
                </h2>
                <p>
                    1. Informal Resolution. Each party agrees that before it
                    seeks arbitration or any other form of legal relief it shall
                    provide written notice to the other of the specific issues
                    in dispute (the “Dispute”). Within thirty days after such
                    notice is received, knowledgeable executives of the party or
                    the individuals themselves shall hold at least one meeting
                    (in person or by video or teleconference) to attempt in good
                    faith to resolve the Dispute. The parties agree to maintain
                    the confidential nature of all disputes and disagreements
                    between them, including, but not limited to, informal
                    negotiations, mediation, or arbitration, except as may be
                    necessary to prepare for or conduct these dispute resolution
                    procedures or unless otherwise required by law or judicial
                    decision. <br />
                    <br />
                </p>
                <p>
                    2. Binding Arbitration. <br />
                    <br />
                </p>
                <p>
                    a. Where informal resolution fails, You agree that any
                    remaining dispute relating in any way to Your use of
                    Clifty’s services and/or products, including the Services,
                    shall be submitted to confidential binding arbitration. If
                    there is a dispute about whether this arbitration provision
                    can be enforced or applies to the Dispute, You and Clifty
                    agree that the arbitrator will decide that issue. However,
                    any claim that all or part of the Class Action Waiver is
                    unenforceable, unconscionable, void, or voidable may be
                    determined only by a court of competent jurisdiction and not
                    by an arbitrator. <br />
                    <br />
                </p>
                <p>
                    b. Arbitration under these Terms is under the Federal
                    Arbitration Act which governs the interpretation and
                    enforcement of this provision. The arbitration will be
                    administered by the JAMS in accordance with its
                    Comprehensive Arbitration Rules and Procedures and the JAMS
                    Consumer Minimum Standards (together, the “Rules”) then in
                    effect (those rules are deemed to be incorporated by
                    reference to this section, and as of the date of these
                    Terms). The arbitration shall be conducted by one (1)
                    arbitrator as selected according to the Rules; the
                    arbitrator's award shall be final and binding and may be
                    entered as a judgment in any court of competent
                    jurisdiction. You have a right to have the arbitration
                    conducted via telephone, or as an in-person hearing in Your
                    hometown area (if You live in the United States) or another
                    location that is reasonably convenient to You. <br />
                    <br />
                </p>
                <p>
                    c. Payment for any and all reasonable JAMS filing,
                    administrative and arbitrator fees will be per the Rules. If
                    the value of Your claim does not exceed $10,000, Clifty will
                    pay for the reasonable filing, administrative and arbitrator
                    fees associated with the arbitration, unless the arbitrator
                    finds that either the substance of Your claim or the relief
                    sought was frivolous or brought for an improper purpose,
                    except that if You have initiated the arbitration claim, You
                    will still be required to pay the lesser of $250 or the
                    maximum amount permitted under the JAMS Rules for
                    arbitration claims initiated by You. You are still
                    responsible for all additional costs that You incur in the
                    arbitration, including without limitation, fees for
                    attorneys or expert witnesses. <br />
                    <br />
                </p>
                <p>
                    3. Opt-Out. You have the right to opt out of binding
                    arbitration and the waiver of class provisions outlined in
                    these Terms within thirty (30) days of registering to use
                    the Services or agreeing to these Terms (or, if this Part VI
                    is amended hereafter, within thirty days of such amendment
                    being effective) by notifying us of Your decision to opt-out
                    with a communication labeled “Arbitration Opt-Out” to &nbsp;
                    <a href="mailto:contact@clifty.io">contact@clifty.io</a> or
                    by mail at 500 Unicorn Park, Suite 300, Woburn, MA 01801,
                    United States, otherwise, You shall be bound to arbitrate
                    disputes in accordance with these Terms. To be effective,
                    the opt-out notice must include Your full name and Your
                    mailing address. If You opt out of these arbitration
                    provisions, Clifty also will not be bound by them. If You
                    have decided to opt out of Binding Arbitration, all Disputes
                    shall be heard in a court of competent jurisdiction. <br />
                    <br />
                </p>
                <p>
                    4. Exceptions to Arbitration. Notwithstanding anything in
                    these Terms to the contrary, You may instead assert Your
                    Dispute in “small claims” court, but only if Your Dispute
                    qualifies, Your Dispute remains only in court, and Your
                    Dispute remains on an individual and non-representative and
                    non-class basis. Further, You and Clifty will have the right
                    to bring an action in a court of proper jurisdiction for:
                    (i) any Dispute related to actual or threatened
                    infringement, misappropriation, or violation of a party's
                    copyrights, trademarks, trade secrets, patents, or other
                    intellectual property rights; or (ii) injunctive or other
                    equitable or conservatory relief. Such claims shall be
                    brought before a judicial proceeding in a court of competent
                    jurisdiction.
                    <br />
                    <br />
                </p>
                <p>
                    5. Waiver of Class Actions and Class Arbitrations. a. YOU
                    AND CLIFTY UNDERSTAND AND AGREE THAT YOU AND CLIFTY MAY EACH
                    BRING CLAIMS AGAINST THE OTHER PARTY ONLY IN AN INDIVIDUAL
                    CAPACITY AND NOT ON A CLASS, COLLECTIVE ACTION, OR
                    REPRESENTATIVE BASIS, INCLUDING, WITHOUT LIMITATION, FEDERAL
                    OR STATE CLASS ACTIONS, OR CLASS ARBITRATIONS. YOU
                    UNDERSTAND AND AGREE THAT YOU AND CLIFTY BOTH ARE WAIVING
                    THE RIGHT TO PURSUE OR HAVE A DISPUTE RESOLVED AS A
                    PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS, COLLECTIVE
                    OR REPRESENTATIVE PROCEEDING. ACCORDINGLY, UNDER THE
                    ARBITRATION PROCEDURES OUTLINED IN THIS SECTION, AN
                    ARBITRATOR SHALL NOT COMBINE OR CONSOLIDATE MORE THAN ONE
                    PARTY’S CLAIMS WITHOUT THE WRITTEN CONSENT OF ALL AFFECTED
                    PARTIES TO AN ARBITRATION PROCEEDING. WITHOUT LIMITING THE
                    GENERALITY OF THE FOREGOING, YOU AND CLIFTY AGREE THAT NO
                    DISPUTE SHALL PROCEED BY WAY OF CLASS ARBITRATION WITHOUT
                    THE WRITTEN CONSENT OF ALL AFFECTED PARTIES. NOTWITHSTANDING
                    THE FOREGOING, THIS SUBSECTION SHALL NOT APPLY TO
                    REPRESENTATIVE PRIVATE ATTORNEYS GENERAL ACT CLAIMS BROUGHT
                    AGAINST CLIFTY, WHICH ARE ADDRESSED SEPARATELY. <br />
                    <br />
                </p>
                <p>
                    6. PAGA Claims. Notwithstanding any other provision of these
                    Terms or the Arbitration provisions, to the fullest extent
                    permitted by law: (1) You and Clifty agree not to bring a
                    representative action on behalf of others under the Private
                    Attorneys General Act of 2004 (“PAGA”), California Labor
                    Code § 2698 et seq., or any subsequent law, in any court or
                    arbitration, and (2) for any claim brought on a private
                    attorney general basis, including under the California PAGA,
                    both You and Clifty agree that any such dispute shall be
                    resolved in arbitration on an individual basis only (i.e.,
                    to resolve whether You have personally been aggrieved or
                    subject to any violations of law) and that such an action
                    may not be used to resolve the claims or rights of other
                    individuals in a single or collective proceeding (i.e., to
                    resolve whether other individuals have been aggrieved or
                    subject to any violations of law) (collectively,
                    “representative PAGA Waiver”). Notwithstanding any other
                    provision of these Terms, the arbitration section or the
                    associated rules, disputes regarding the scope,
                    applicability, enforceability, revocability, or validity of
                    this representative PAGA Waiver may be resolved only by a
                    civil court of competent jurisdiction and not by an
                    arbitrator. If any provision of this representative PAGA
                    Waiver is found to be unenforceable or unlawful for any
                    reason: (i) the unenforceable provision shall be severed
                    from these Terms; (ii) severance of the unenforceable
                    provision shall have no impact whatsoever on any arbitration
                    provisions or the requirement that any remaining claims be
                    arbitrated on an individual basis according to the
                    arbitration provisions; and (iii) any such representative
                    PAGA or other representative private attorneys general act
                    claims must be litigated in a civil court of competent
                    jurisdiction and not in arbitration. To the extent that
                    there are any Disputes to be litigated in a civil court of
                    competent jurisdiction because a civil court of competent
                    jurisdiction determines that the representative PAGA Waiver
                    is unenforceable concerning those Disputes, the parties
                    agree that litigation of those Disputes shall be stayed
                    pending the outcome of any individual Disputes in
                    arbitration. <br />
                    <br />
                </p>
                <p>
                    7. In the event of a legal dispute between Users over which
                    User has the right to be in possession of a Clifty NFT, we
                    reserve the right to block any additional transfers of such
                    Clifty NFT pending written instructions of all parties to
                    the dispute or the order of a court of competent
                    jurisdiction.
                </p>
                <h2>PART X - MISCELLANEOUS</h2>
                <p>
                    1. Force Majeure. Clifty shall not be liable to You, nor be
                    deemed to have defaulted under or breached these Terms, for
                    any delay in or impairment or failure of performance or in
                    providing the Services <br />
                    <br />
                </p>
                <p>
                    2. Platform, resulting in whole or in part from externally
                    caused interference, damage, or disruption to network
                    components, transmission, or pipeline facilities (which
                    includes network, electrical, or other common systems);
                    irreparable component, transmission, or pipeline failure
                    (the failure or interruption of pipeline facilities); acts
                    of God; severe weather conditions; governmental decrees or
                    controls; strikes, labor disputes, acts of war or civil
                    unrest; acts of third parties, inability to obtain permits
                    and licenses, or supplies or any other circumstances or
                    causes beyond the reasonable commercial control of Clifty.
                    <br />
                    <br />
                </p>
                <p>
                    3. Complete Agreement. These Terms and any related
                    information are incorporated herein by reference and
                    collectively constitute the entire agreement between You and
                    Clifty regarding the subject matter hereof, subject to
                    applicable law. <br />
                    <br />
                </p>
                <p>
                    4. Severability. In the event any one or more of the
                    provisions of these Terms is for any reason to be invalid or
                    unenforceable, the remaining provisions of such Terms shall
                    be unimpaired, and the invalid or unenforceable provision
                    shall be replaced by a provision that is valid, and
                    enforceable, comes closest to the intention of the parties
                    underlying the invalid or unenforceable provision(s).
                    <br />
                    <br />
                </p>
                <p>
                    5. Governing Law and Forum. These Terms are governed by the
                    laws of the State of California, without giving effect to
                    its conflict of law principles, and the proper venue for any
                    disputes arising out of or relating to any of the same will
                    be the arbitration venue outlined in Part VI, or if
                    arbitration does not apply, the state or federal courts
                    sitting in the State of California. You waive any and all
                    objections to such jurisdiction and venue and waive personal
                    service of process and agree that any summons and complaint
                    commencing an action in any such court shall be properly
                    served and shall confer personal jurisdiction if served by
                    registered or certified mail. Any action or suit brought
                    concerning the Terms shall be tried by a court and not by a
                    jury. YOU WAIVE ANY RIGHT TO A TRIAL BY JURY IN ANY SUCH
                    ACTION OR SUIT. You and Clifty agree that the United Nations
                    Convention on Contracts for the International Sale of Goods
                    will not apply to the interpretation or construction of
                    these Terms. <br />
                    <br />
                </p>
                <p>
                    6. Updating these Terms. We may modify these Terms from time
                    to time in which case we will update the “Last Revised” date
                    at the top of these Terms. If we make changes that are
                    material, we will use reasonable efforts to attempt to
                    notify You, such as by e-mail and/or by placing a prominent
                    notice on the Services. However, it is Your sole
                    responsibility to review these Terms from time to time to
                    view any such changes. The updated Terms will be effective
                    as of the time of posting, or such later date as may be
                    specified in the updated Terms. Your continued access or use
                    of the Services after the modifications have become
                    effective will be deemed Your acceptance of the modified
                    Terms. <br />
                    <br />
                </p>
                <p>
                    7. Injunctive Relief. You agree that a breach of these Terms
                    will cause irreparable injury to Clifty for which monetary
                    damages would not be an adequate remedy and Clifty shall be
                    entitled to equitable relief in addition to any remedies it
                    may have hereunder or at law without a bond, other security
                    or proof of damages. <br />
                    <br />
                </p>
                <p>
                    8. Theft and Fraudulent Activity. You acknowledge that
                    Clifty reserves the right to report unusual or suspicious
                    activity to the proper authorities. You agree that Clifty
                    will not be held responsible or liable to You or any other
                    person for such actions except as required by law. Clifty
                    will report any activities that we reasonably believe
                    constitute fraud or theft to the appropriate law enforcement
                    authorities and may prosecute such activities to the full
                    extent of the law. To the extent permitted by law, Clifty
                    will retain the proceeds resulting from such fraudulent
                    activity or theft and use those funds to pay for damages and
                    losses resulting from such fraudulent activity or theft.{" "}
                    <br />
                    <br />
                </p>
                <p>
                    9. Assignment. These Terms and the licenses granted
                    hereunder may be assigned by Clifty but may not be assigned
                    by You without the prior express written consent of Clifty.
                    <br />
                    <br />
                </p>
                <p>
                    10. Termination of License and Your Account. If You breach
                    any of the provisions of these Terms, all licenses granted
                    by Clifty will terminate automatically. Additionally, Clifty
                    reserves the right, at its sole discretion, to remove,
                    suspend, revoke, terminate, or close Your Account (including
                    disabling, invalidating, or otherwise rendering inoperable
                    Your Services Wallet or Payments Account) and/or the
                    Services (or any part of the foregoing), with or without
                    notice, for whatever reason and without explanation. If
                    Clifty deletes Your Account for any suspected breach of
                    these Terms by You, You are prohibited from re-registering
                    for the Services under a different name. In the event that
                    credit card, debit card, or other payment methods are
                    returned from Your bank unpaid, Clifty reserves the right,
                    at its sole discretion, to remove these pre-deposited funds
                    from Your Account or Your Payments Account, and Clifty
                    further reserves the right, at its sole discretion, to
                    remove any ad NFTs from Your Account. <br />
                    <br />
                </p>
                <p>
                    11. Survival. All sections which by their nature should
                    survive the termination of these Terms shall continue in
                    full force and effect after and notwithstanding any
                    termination of these Terms by Clifty or You. Termination
                    will not limit any of Clifty's other rights or remedies at
                    law or in equity.
                    <br />
                    <br />
                </p>
                <p>
                    12. California Residents. If You are a California resident,
                    in accordance with Cal. Civ. Code § 1789.3, You may report
                    complaints to the Complaint Assistance Unit of the Division
                    of Consumer Services of the California Department of
                    Consumer Affairs by contacting them in writing at 1625 North
                    Market Blvd., Suite N 112 Sacramento, CA 95834, or by
                    telephone at (800) 952-5210. <br />
                    <br />
                </p>
                <p>
                    13. Export Laws. You agree that You will not export or
                    re-export, directly or indirectly, the Services, the Clifty
                    NFTs, and/or other information or materials provided by
                    Clifty hereunder, to any country for which the United States
                    or any other relevant jurisdiction requires any export
                    license or other governmental approval at the time of export
                    without first obtaining such license or approval. In
                    particular, but without limitation, the Services may not be
                    exported or re-exported (a) into any U.S. embargoed
                    countries or any country that has been designated by the
                    U.S. Government as a “terrorist supporting” country, or (b)
                    to anyone listed on any U.S. Government list of prohibited
                    or restricted parties, including the Office of Foreign
                    Assets Control of the U.S. Treasury Department's list of
                    Specially Designated Nationals and/or Blocked Person, the
                    Bureau of Industry and Security of the U.S. Department of
                    Commerce Denied Person's List or Entity List, or any similar
                    list promulgated by an official agency or department of the
                    U.S. government. By using the Services, You represent and
                    warrant that You are not located in any such country or on
                    any such list. You are responsible for and hereby agree to
                    comply at Your sole expense with all applicable United
                    States export laws and regulations.
                </p>
                <h1>Contact</h1>
                <p>
                    General and specific inquiries regarding the Services or
                    these Terms should be directed to: <br />
                    <br />
                    <b>Clifty Inc,</b>
                    <br />
                    500 Unicorn Park,
                    <br />
                    Suite 300
                    <br />
                    Woburn, MA 01801
                    <br />
                    United States
                    <br />
                    <br />
                    <b>Email:</b>
                    <br />
                    <a href="mailto:contact@clifty.io">contact@clifty.io</a>
                </p>
            </div>
        </>
    );
};

export default Terms;
