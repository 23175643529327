import { useEffect, useState } from "react";
import "./dashboard.css";
import { Outlet, useNavigate } from "react-router";

function Dashboard({ login }) {
  const [eventData, setEventData] = useState({
    total: 0,
    active: 0,
  });

  const [showTotalTooltip, setShowTotalTooltip] = useState(false);
  const [showActiveTooltip, setShowActiveTooltip] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (!user) {
      return navigate("/");
    }

    const getdata = async () => {
      const res = await fetch(
        process.env.REACT_APP_BASEURL + "/members/dashboardcounts",
        {
          headers: {
            Authorization: `Bearer ${user.sessionToken}`,
          },
        }
      );
      const data = await res.json();
      setEventData(data?.pins);
    };
    getdata();
  }, [navigate]);

  const handlenavigate = (page) => navigate(`/dashboard/${page}`);
  return (
    <>
      <div>
        <h1>Dashboard</h1>
        <div className="contents">
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div
              className="card"
              onClick={() => handlenavigate("pins")}
              style={{ width: "18rem", marginBottom: "1.5rem" }}
            >
              <div className="card-body">
                <div
                  style={{
                    display: "flex",
                    alignItems: "baseline",
                    position: "relative",
                  }}
                >
                  <h5 className="card-title" style={{ marginRight: "0.2rem" }}>
                    Total Pins
                  </h5>

                  <div
                    onMouseEnter={() => setShowTotalTooltip(true)}
                    onMouseLeave={() => setShowTotalTooltip(false)}
                    style={{
                      position: "relative",
                    }}
                  >
                    {showTotalTooltip && (
                      <div
                        style={{
                          background: "white",
                          width: "15rem",
                          height: "fit-content",
                          padding: "5px",
                          position: "absolute",
                          bottom: "2rem",
                          border: "1px solid gray",
                          borderRadius: "10px",
                        }}
                      >
                        Pin is a digital collectible in the form of NFT. You can
                        create Pin which can be distributed to your audience as
                        a collectible.
                      </div>
                    )}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-info-circle"
                      viewBox="0 0 16 16"
                      data-toggle="tooltip"
                      data-placement="top"
                      style={{ marginLeft: "6px", marginBottom: "4px" }}
                    >
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                      <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                    </svg>
                  </div>
                </div>
                <p className="card-text">{eventData.total}</p>
              </div>
            </div>
            <div
              className="card"
              style={{ width: "18rem" }}
              onClick={() => handlenavigate("pins/active")}
            >
              <div className="card-body">
                <div
                  style={{
                    display: "flex",
                    alignItems: "baseline",
                    position: "relative",
                  }}
                >
                  <h5 className="card-title" style={{ marginRight: "0.2rem" }}>
                    Active Pins
                  </h5>

                  <div
                    onMouseEnter={() => setShowActiveTooltip(true)}
                    onMouseLeave={() => setShowActiveTooltip(false)}
                    style={{
                      position: "relative",
                    }}
                  >
                    {showActiveTooltip && (
                      <div
                        style={{
                          background: "white",
                          width: "15rem",
                          height: "fit-content",
                          padding: "5px",
                          position: "absolute",
                          bottom: "2rem",
                          border: "1px solid gray",
                          borderRadius: "10px",
                        }}
                      >
                        Pin is a digital collectible in the form of NFT. You can
                        create Pin which can be distributed to your audience as
                        a collectible.
                      </div>
                    )}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-info-circle"
                      viewBox="0 0 16 16"
                      data-toggle="tooltip"
                      data-placement="top"
                      style={{ marginLeft: "6px", marginBottom: "4px" }}
                    >
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                      <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                    </svg>
                  </div>
                </div>
                <p className="card-text">{eventData.active}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Outlet />
    </>
  );
}

export default Dashboard;
