import { useNavigate } from "react-router";
import { useContext, useEffect, useState } from "react";
import { AlertContext } from "../../App";

const PinsTable = () => {
  const navigate = useNavigate();
  const [allpins, setPins] = useState();

  const alertContext = useContext(AlertContext);

  useEffect(() => {
    getPins();
  }, [alertContext, navigate]);

  const getPins = async () => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (!user) {
      return navigate("/");
    }
    try {
      const res = await fetch(
        `${process.env.REACT_APP_BASEURL}/members/pins`,
        {
          headers: {
            Authorization: `Bearer ${user.sessionToken}`,
          },
        }
      );

      const data = await res.json();
      if (window.location.pathname.includes("active")) {
        setPins(data?.filter((pin) => pin?.active));
      } else {
        setPins(data);
      }
    } catch (err) {
      alertContext.show = true;
      alertContext.variant = "danger";
      if (err.toString()?.includes("Forbidden")) {
        alertContext.msg = "Session expired, please sign in";
        localStorage.removeItem("user");
        navigate("/");
      } else {
        alertContext.msg = "Error getting pins";
      }
    }
  };

  const handleactivate = (e, id) => {
    const { name } = e.target;
    const user = JSON.parse(localStorage.getItem("user"));
    fetch(`${process.env.REACT_APP_BASEURL}/members/pins/${id}/${name}`, {
      headers: {
        Authorization: `Bearer ${user.sessionToken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => console.log(data))
      .finally(() => getPins());
  };
  return (
    <>
      <div className="row">
        <div class="col-2">
          <b>Name</b>
        </div>
        <div class="col" style={{ textAlign: "center" }}>
          <b>Tokens</b>
        </div>
        <div class="col" style={{ textAlign: "center" }}>
          <b>Limit</b>
        </div>
        <div class="col" style={{ textAlign: "center" }}>
          <b>Status</b>
        </div>
        <div class="col" style={{ textAlign: "center" }}>
          <b>Type</b>
        </div>
        <div class="col" style={{ textAlign: "center" }}>
          <b>Action</b>
        </div>
      </div>

      {allpins &&
        allpins.map((pin) => {
          return (
            <div className="row">
              <div
                class="col-2"
                style={{
                  verticalAlign: "top",
                }}
              >
                <img
                  src={`https://asset.clifty.io/${pin.artifact.replace(
                    "ipfs://",
                    "ipfs/"
                  )}?pinataGatewayToken=Jj_K5bQqbTXNlJhh6FG9tf31rZP5XPWSGRGEf01BL4S9Ck8EZKABX6nZ-PwyNHf9`}
                  className="pin-image card-img-top mx-auto d-block"
                  alt={pin.name}
                />
                <a className="detail-link" href={`/pins/${pin?.id}`}>
                  {pin.name}
                </a>
              </div>
              <div class="col" style={{ textAlign: "center" }}>
                {pin.maxtokens}
              </div>
              <div class="col" style={{ textAlign: "center" }}>
                {pin.limitperperson}
              </div>
              <div class="col" style={{ textAlign: "center" }}>
                {pin.active ? "Active" : "Inactive"}
              </div>
              <div style={{ textAlign: "center" }} class="col">
                {pin.evtype}
              </div>
              <div style={{ textAlign: "center" }} class="col">
                {pin.active === 0 ? (
                  <button
                    name="Enable"
                    className="active-navlink"
                    onClick={(e) => handleactivate(e, pin.id)}
                    style={{ border: "none" }}
                  >
                    Enable
                  </button>
                ) : (
                  <button
                    name="Disable"
                    onClick={(e) => handleactivate(e, pin.id)}
                    style={{ border: "none" }}
                  >
                    Disable
                  </button>
                )}
              </div>
            </div>
          );
        })}
    </>
  );
};

export default PinsTable;
